<template>
  <div class="medical-record">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-row class="mt-3">
      <v-col cols="12" lg="8" md="8" sm="12">
        <span class="heading-2">Medical Records</span>
        <v-tabs v-model="tab" background-color="transparent" color="primary">
          <v-tab class="text-capitalize tab_item" href="#medical-record"> Medical records</v-tab>
          <v-tab class="text-capitalize tab_item" href="#my-health-data"> My Health Data</v-tab>
          <v-tab class="text-capitalize tab_item" href="#files"> Files</v-tab>
          <v-tab class="text-capitalize tab_item" href="#dependents"> Dependents</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12">
        <v-row class="justify-end">
          <v-col
            v-if="tab === 'my-health-data' && hasAnyMonitoringOrders"
            class="text-right"
            cols="12"
            lg="6"
            md="6"
            sm="12"
          >
            <v-menu offset-y right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :class="{ 'full-width': $vuetify.breakpoint.smAndDown }"
                  class="healthdata__add-btn"
                  color="success"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add Health Data
                </v-btn>
              </template>
              <v-list>
                <template v-for="card in cards">
                  <v-list-item v-if="hasOrder(card.kind)" :key="card.kind" class="healthdata__item">
                    <v-list-item-title class="healthdata__item-title" @click="openMonitoringFormByKind(card.kind)"
                      >{{ card.display }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="tab === 'search'">
        <v-btn color="primary" small @click="backToRecords">Back to Records</v-btn>
      </v-col>
    </v-row>
    <v-divider class="mb-4"></v-divider>
    <MedicalRecordEncounters v-show="tab === 'medical-record'" :showFiles="false" @gotoFiles="gotoFiles" />
    <PatientMedia v-show="tab === 'files'" />
    <PatientHealthData v-show="tab === 'my-health-data'" :obType="obType" />
    <PatientMedia v-show="tab === 'dependents'" component="dependents" />
    <div v-show="tab === 'search'">
      <v-sheet class="py-4 px-1">
        <v-chip-group :show-arrows="true" active-class="primary--text" column @change="onChipChange">
          <v-chip v-for="tag in tags" :key="tag" class="text-primary v-chip--active" label>
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </v-sheet>
      <v-progress-linear v-if="searchLoading" color="primary"></v-progress-linear>
      <MedicalRecordSearchResult v-if="searchResult.length" :result="searchResult" :search="search" />
      <p v-if="noResults" class="heading-4 text-center">Nothing found</p>
      <v-data-table :headers="headers" :items="recentSearches" disable-sort mobile-breakpoint="0">
        <template v-slot:item.remove="{ item }">
          <v-btn icon small @click="removeSearch(item.recent)">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <EditForm @onSave="onHealthDataSave" @onSickModalOpen="openSickModal" />
    <Success :dialog="successModal" @close="successModal = false" />
    <Sick :dialog="sickModal" @close="sickModal = false" />
    <AddNote
      :dialog="addNoteDialog"
      :editId="editId"
      :mode="noteMode"
      @close="closeAddNoteDialog"
      @save="reloadNotes"
    />
  </div>
</template>

<script>
import camelCase from "lodash/camelCase";
import { mapActions, mapState } from "pinia";

import { VisitNoteAPI } from "@/api/visitNote";
import MedicalRecordEncounters from "@/components/MedicalRecord/Index";
import PatientHealthData from "@/components/MedicalRecord/PatientHealthData";
import PatientMedia from "@/components/MedicalRecord/PatientMedia";
import AddNote from "@/components/MedicalRecord/PrivateNotes/AddNote";
import MedicalRecordSearchResult from "@/components/MedicalRecord/Search";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useObservationsStore } from "@/pinia-store/observations";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";
import { RolesEnum } from "@/types/Roles.enum";
import { observationTypes } from "@/utils/observationTypes";
import EditForm from "@/views/Patient/Monitoring/EditForm";
import Sick from "@/views/Patient/Monitoring/Sick";
import Success from "@/views/Patient/Monitoring/Success";

export default {
  name: "MedicalRecord",
  components: {
    AddNote,
    PatientMedia,
    MedicalRecordEncounters,
    MedicalRecordSearchResult,
    PatientHealthData,
    Success,
    Sick,
    EditForm,
  },
  data() {
    return {
      loading: false,
      tab: "medical-record",
      search: "",
      searchActive: false,
      recentSearches: [],
      searchResult: [],
      searchLoading: false,
      noResults: false,
      successModal: false,
      sickModal: false,
      obType: null,
      addNoteDialog: false,
      noteMode: "",
      editId: null,
    };
  },
  computed: {
    ...mapState(useMonitoringStore, ["hasAnyMonitoringOrders", "orders"]),
    ...mapState(useMedicalRecordStore, ["patient"]),
    ...mapState(useAuthStore, ["uid", "role"]),
    tags() {
      return [
        this.$i18n.t("visits.summary.medicalInformation"),
        this.$i18n.t("visits.summary.medications"),
        this.$i18n.t("visits.chiefComplaint"),
        this.$i18n.t("visits.summary.encounterNotes"),
        this.$i18n.t("visits.summary.allergies"),
        this.$i18n.t("visits.summary.pmh"),
        this.$i18n.t("visits.summary.psh"),
        this.$i18n.t("visits.schedule.second.generalMedicalInformation"),
        this.$i18n.t("visits.description.visitReason"),
        this.$i18n.t("visits.description.patientInformation"),
      ];
    },
    cards() {
      return observationTypes;
    },
    headers() {
      return [
        {
          text: "Recent Searches",
          value: "recent",
          width: "90%",
        },
        {
          text: "Remove",
          value: "remove",
          width: "10%",
        },
      ];
    },
  },
  methods: {
    ...mapActions(useMedicalRecordStore, ["getPatient", "getEncounters", "clearAllFields", "getPrivateNotes"]),
    ...mapActions(useMediaStore, [
      "fetchFiles",
      "emptyFiles",
      "setFileType",
      "setName",
      "fetchDependentsFiles",
      "setUid",
      "setFiles",
      "setComponent",
    ]),
    ...mapActions(useObservationsStore, [
      "setPatientId",
      "openMonitoringFormByKind",
      "changeEditFormField",
      "clearEditForm",
    ]),
    hasOrder(kind) {
      return this.orders.some((i) => i.kind === kind);
    },
    async onChipChange(event) {
      if (this.search) {
        await this.searchInRecords(this.search);
        if (this.tags[event]) {
          this.searchResult = this.searchResult.filter((result) => {
            const [key] = Object.keys(result);
            return key === camelCase(this.tags[event]);
          });
        }
      } else {
        this.search = this.tags[event];
        if (this.search) {
          await this.searchInRecords(camelCase(this.search));
        }
      }
    },
    openAddNoteDialog() {
      this.addNoteDialog = true;
    },
    closeAddNoteDialog() {
      this.addNoteDialog = false;
    },
    openEditNoteDialog(id) {
      this.noteMode = "edit";
      this.editId = id;
      this.addNoteDialog = true;
    },
    async reloadNotes() {
      const { patientId } = this.$route.params;
      await this.getPrivateNotes({ patientId, practitionerId: this.uid });
      if (this.noteMode === "edit") {
        this.noteMode = "";
        this.editId = null;
      }
      this.closeAddNoteDialog();
    },
    gotoFiles() {
      this.tab = "files";
    },
    onHealthDataSave() {
      if (!this.sickModal) this.successModal = true;
    },
    openSickModal() {
      this.sickModal = true;
    },
    onAdd(card) {
      this.clearEditForm();
      this.changeEditFormField({ field: "kind", value: card.kind });
      this.changeEditFormField({ field: "display", value: card.display });
    },
    async searchInRecords(value) {
      this.searchResult = [];
      if (value !== "" && value) {
        const recent = JSON.parse(localStorage.getItem("recentSearches"));
        if (recent !== null) {
          const currentDoctorsSearchIndex = recent.findIndex((searches) => searches.id === this.uid);
          if (currentDoctorsSearchIndex !== -1) {
            if (recent[currentDoctorsSearchIndex].searches.indexOf(value) === -1) {
              recent[currentDoctorsSearchIndex].searches.push(this.search);
            }
          } else {
            recent.push({
              id: this.uid,
              searches: [value],
            });
          }
          localStorage.setItem("recentSearches", JSON.stringify(recent));
        } else {
          localStorage.setItem("recentSearches", JSON.stringify([{ id: this.uid, searches: [value] }]));
        }
        this.searchLoading = true;
        const response = await VisitNoteAPI.searchVisitNote(this.patient.id, value);
        this.noResults = !response.length;
        response.forEach((result) => {
          const values = Object.values(result);
          values.forEach((objValue) => {
            for (const key in objValue) {
              const parsedInformation = JSON.stringify(objValue[key]);
              if (key === value || parsedInformation.search(value) !== -1) {
                const exists = this.searchResult.findIndex((item) => item[key]);
                if (exists !== -1) {
                  if (Array.isArray(objValue[key])) {
                    const [arrValues] = objValue[key];
                    this.searchResult[exists][key].push(arrValues);
                  } else {
                    this.searchResult[exists][key].push(objValue[key]);
                  }
                } else {
                  if (Array.isArray(objValue[key])) {
                    this.searchResult.push({
                      [key]: objValue[key],
                    });
                  } else {
                    this.searchResult.push({
                      [key]: [objValue[key]],
                    });
                  }
                }
              }
            }
          });
        });
        this.tab = "medical-record";
        this.getRecentSearches();
      }
    },
    showSearchWindow() {
      this.tab = "search";
      this.getRecentSearches();
    },
    async backToTable() {
      await this.$router.push("/practitioner/patients");
    },
    removeSearch(name) {
      let recent = JSON.parse(localStorage.getItem("recentSearches"));
      const currentDoctorsSearchIndex = recent.findIndex((searches) => searches.id === this.uid);
      recent[currentDoctorsSearchIndex].searches = recent[currentDoctorsSearchIndex].searches.filter(
        (item) => item !== name,
      );
      localStorage.setItem("recentSearches", JSON.stringify(recent));
      this.getRecentSearches();
    },
    getRecentSearches() {
      if (localStorage.getItem("recentSearches") !== null) {
        const recent = JSON.parse(localStorage.getItem("recentSearches"));
        const currentDoctorsSearchIndex = recent.findIndex((searches) => searches.id === this.uid);
        if (currentDoctorsSearchIndex !== -1) {
          this.recentSearches = recent[currentDoctorsSearchIndex].searches.reverse().map((item) => ({
            recent: item,
          }));
        }
      }
    },
    backToRecords() {
      this.tab = "medical-record";
      this.searchActive = false;
      this.search = "";
    },
  },
  watch: {
    tab: {
      immediate: true,
      handler(val) {
        if (val === "files") {
          this.setFiles([]);

          this.setComponent("");
          this.fetchFiles();
        }
        if (val === "dependents") {
          this.setFiles([]);
          this.setComponent("dependents");
          this.fetchDependentsFiles();
        }
      },
    },
  },
  async mounted() {
    this.setFiles([]);
    const patientId = this.role === RolesEnum.Patient ? this.uid : this.$route.params.patientId;
    await this.setUid(patientId);

    if (this.role === RolesEnum.Patient && patientId !== this.uid) {
      await this.$router.push({ path: "/patient/visits" });
    }
    this.loading = true;
    if (this.role !== RolesEnum.Patient) {
      await this.getPatient(patientId);
    }
    if (this.$route.query.monitoring) {
      const observation = observationTypes.find((ob) => ob.kind === this.$route.query.monitoring);
      if (observation) {
        this.tab = "my-health-data";
        this.obType = this.$route.query.monitoring;
      }
    }
    await this.getEncounters({ patientId, status: [EncounterStatusEnum.finished] });
    await this.fetchFiles();
    this.loading = false;
  },
  beforeDestroy() {
    this.clearAllFields();
  },
};
</script>

<style lang="scss" scoped>
.medical-record {
  background: #fafafa;
  padding: 0 30px;
}

.tab_item {
  letter-spacing: normal;
  color: #25233a;
  font-size: 14px;
  opacity: 0.4;

  &.v-tab--active {
    color: var(--primary);
    opacity: 1;
  }
}

.healthdata__add-btn {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  box-shadow: none;
  text-transform: capitalize;
}

.healthdata__item.v-list-item {
  transition: 0.3s ease;

  &:hover {
    background-color: rgba(47, 207, 111, 0.08);
    color: #2fcf6f;
    cursor: pointer;
  }
}
</style>
